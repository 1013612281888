@font-face {
  font-family: MainFont;
  src: url("../../Fonts/Rubik-VariableFont_wght.ttf");
}

body {
  font-family: MainFont;
}

.MainTap {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  padding-top: 20px;
  overflow-y: scroll;
}

.MainTap img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
}

.home-container {
  width: 500px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  font-family: MainFont;
}

.home-container h2 {
  color: #007bff;
  font-family: MainFont;
  font-size: 50px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

label {
  font-weight: bold;
  font-family: MainFont;
  color: #00b36b;
}

input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 10px;
  min-width: 300px;
  border: none;
  border-bottom: 3px solid #007bff;
  transition: border-color 0.3s;
  font-family: MainFont;
  font-size: 20px;
}

input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #007bff;
  outline: none;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: MainFont;
}

button:hover {
  background-color: #0056b3;
}
h3 {
  font-family: MainFont;
  color: #00b36b;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
  font-family: MainFont;
}

@media (max-width: 768px) {
  .MainTap {
    flex-direction: column;
    align-items: flex-start;
  }

  .MainTap img {
    width: 90%;
  }
}
.notes-container {
  background-color: #20b850;
  color: #fff;
  padding: 15px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: fit-content;
  text-align: start;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.home-container {
  margin-top: 60px;
}
