.profile-container {
  width: 500px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  font-family: MainFont;
}

h2 {
  color: #007bff;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: MainFont;
}

.input-group {
  margin-bottom: 15px;
}

.signature-pad {
  margin-top: 20px;
}

.signature-canvas {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.save-signature-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
  font-family: MainFont;
}

.save-signature-button:hover {
  background-color: #0056b3;
}

.signature-preview {
  margin-top: 15px;
  text-align: center;
}

.signature-preview img {
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}
